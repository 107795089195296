import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Modal, Row } from "antd";
import LineChartWrap from "components/charts/LineChartWrap";
import Table from "components/Table";
import DtpTable from "modules/dtp/DtpTable";
import { Card, Title, Container } from "components/common/Elements";
import ExportExcel from "components/ExportExcel";
import {
  clearCrimeFilter,
  fetchCrimeAll,
  selectCrime,
  setCrimeFilter,
  setDateCrime,
  setFeaturedCategory,
  setSelectedCategory,
} from "modules/operational/actions";
import {
  getAllCrime,
  getAllCrimeByCategory,
  getAllCrimeByCategoryLabels,
  getAllCrimeByCategoryValues,
  getAllCrimeByDistrict,
  getAllCrimeByDistrictLabels,
  getAllCrimeByDistrictValues,
  getAllCrimeByIncision,
  getAllCrimeByIncisionLabels,
  getAllCrimeByIncisionValues,
  getAllCrimeByOrgan,
  getAllCrimeByOrganValues,
  getCrimeEndDate,
  getCrimeStartDate,
  getFeaturedCategories,
  getFirstGraphCrimes,
  getFourthGraphCrimes,
  getLoadingCrime,
  getSecondGraphCrimes,
  getSelectedCrime,
  getThirdGraphCrimes,
} from "modules/operational/selectors";

import LoadingSpinner from "components/LoadingSpinner";
import RangeDateFilter from "components/RangeDateFilter";
import HorizontalBarChart from "components/charts/HorizontalBarChart";
import FilterSelect from "components/FilterSelect";
import moment from "moment";
import MapWrap from "components/common/MapWrap";

export function formatCrimeDate(value) {
  return value ? moment(value).format("DD.MM.YYYY HH:mm:ss") : "";
}

export const mapStateToProps = (state) => ({
  loading: getLoadingCrime(state),
  startDate: getCrimeStartDate(state),
  endDate: getCrimeEndDate(state),
  selected: getSelectedCrime(state),
  featuredCategories: getFeaturedCategories(state),
  crime: getAllCrime(state),
  crimeByRegion: getAllCrimeByDistrict(state),
  crimeByRegionValues: getAllCrimeByDistrictValues(state),
  crimeByRegionLabels: getAllCrimeByDistrictLabels(state),
  crimeByIncisionLabels: getAllCrimeByIncisionLabels(state),
  crimeByIncision: getAllCrimeByIncision(state),
  crimeByIncisionValues: getAllCrimeByIncisionValues(state),
  crimeByOrgan: getAllCrimeByOrgan(state),
  crimeByOrganValues: getAllCrimeByOrganValues(state),
  crimeByCategory: getAllCrimeByCategory(state),
  crimeByCategoryLabels: getAllCrimeByCategoryLabels(state),
  crimeByCategoryValues: getAllCrimeByCategoryValues(state),
  firstGraphicsValues: getFirstGraphCrimes(state),
  secondGraphicsValues: getSecondGraphCrimes(state),
  thirdGraphicsValues: getThirdGraphCrimes(state),
  fourthGraphicsValues: getFourthGraphCrimes(state),
});

const mapDispatchToProps = {
  setDateCrime,
  fetchCrimeAll,
  setCrimeFilter,
  clearCrimeFilter,
  selectCrime,
  setFeaturedCategory,
  setSelectedCategory,
};

class Crime extends Component {
  state = { visible: false, records: [], filters: {} };

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.props.fetchCrimeAll(startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.props;
    const { startDate: pStartDate, endDate: pEndDate } = prevProps;
    if (startDate !== pStartDate || endDate !== pEndDate) {
      this.props.fetchCrimeAll(startDate, endDate);
    }
  }

  handleClick = (filter) => {
    const { startDate, endDate } = this.props;
    this.props.setCrimeFilter(filter);
    this.props.fetchCrimeAll(startDate, endDate);
  };

  handleClear = (type) => {
    const { startDate, endDate } = this.props;
    this.props.clearCrimeFilter(type);
    this.props.fetchCrimeAll(startDate, endDate);
  };

  showModal = (type, category) => {
    let records = [];
    if (type === "region") {
      records = this.props.crimeByRegion[category];
    } else if (type === "incision") {
      records = this.props.crimeByIncision[category];
    } else if (type === "organ") {
      records = this.props.crimeByOrgan[category];
    } else if (type === "category") {
      records = this.props.crimeByCategory[category];
    }
    this.setState({
      visible: true,
      records,
    });
  };

  showIncision = (category) => {
    this.props.setSelectedCategory(category);
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      records: [],
    });
  };
  handleOk = (e) => {
    this.setState({
      filtersVisible: false,
    });
  };

  handleCancelModal = (e) => {
    this.setState({
      filtersVisible: false,
    });
  };
  showFilters = () => {
    this.setState({
      filtersVisible: !this.state.filtersVisible,
    });
  };

  render() {
    const chartHeight = "47vh";
    const { visible, records } = this.state;
    let { t } = this.props;
    const {
      loading,
      startDate,
      endDate,
      selected,
      featuredCategories,
      crime,
      crimeByRegionValues,
      crimeByRegionLabels,
      crimeByOrganValues,
      crimeByCategoryLabels,
      crimeByCategoryValues,
      firstGraphicsValues,
      secondGraphicsValues,
      thirdGraphicsValues,
      setDateCrime,
      selectCrime,
      setFeaturedCategory,
      isMobile,
    } = this.props;
    let mapData =
      crime &&
      crime.map((el) => ({
        ...el,
        lat: el.y,
        lon: el.x,
      }));
    let tableRows = [
      { name: t("publicSafety.crime.category"), key: "crName" },
      { name: t("publicSafety.crime.severity"), key: "hardCode" },
      { name: t("publicSafety.crime.article"), key: "article" },
      {
        name: t("publicSafety.crime.dateOfUD"),
        key: (o) => formatCrimeDate(o.date_sover),
      },
      {
        name: t("publicSafety.crime.dateOfInitiationOfUD"),
        key: (o) => formatCrimeDate(o.date_vozb),
      },
      { name: t("publicSafety.crime.KUI"), key: "udv" },
      { name: t("publicSafety.crime.authority"), key: "organ" },
      { name: t("publicSafety.crime.street"), key: "street" },
      { name: t("publicSafety.crime.house"), key: "flat" },
    ];
    return (
      <>
        <Row className="mb20" gutter={16}>
          <Col span={isMobile ? 24 : 6}>
            <RangeDateFilter
              startDate={startDate}
              endDate={endDate}
              setDate={setDateCrime}
              width={"100"}
            />
          </Col>
          <Col span={isMobile ? 24 : 6}>
            <FilterSelect
              options={crimeByRegionLabels}
              filterField="district"
              handleChange={this.handleClick}
              handleClear={this.handleClear}
              placeholder={t("publicSafety.crime.area")}
              allowClear
              maxWidth="100%"
            />
          </Col>
          <Col span={isMobile ? 24 : 6}>
            <FilterSelect
              options={crimeByCategoryLabels}
              filterField="crName"
              handleChange={this.handleClick}
              handleClear={this.handleClear}
              placeholder={t("publicSafety.crime.category")}
              allowClear
              maxWidth="100%"
            />
          </Col>
          <ExportExcel
            filename="Преступления"
            data={crime}
            fields={[
              {
                title: "Район",
                dataIndex: "district",
              },
              {
                title: "Категория",
                dataIndex: "crName",
              },
              {
                title: "Тяжесть",
                dataIndex: "hardCode",
              },
              {
                title: "Статья",
                dataIndex: "article",
              },
              {
                title: "Дата совершения УД",
                dataIndex: "date_sover",
              },
              {
                title: "Дата возбуждения УД",
                dataIndex: "date_vozb",
              },
              {
                title: "Номер КУИ",
                dataIndex: "udv",
              },
              {
                title: "Орган",
                dataIndex: "organ",
              },
              {
                title: "Улица",
                dataIndex: "street",
              },
              { title: "Дом", dataIndex: "flat" },
            ]}
          />
        </Row>
        <Row>
          <Title>
            {t("publicSafety.crime.numberOfCrimes")}: {crime.length}
          </Title>
        </Row>
        <Container>
          <Row>
            <Col span={isMobile ? 24 : 16}>
              <div style={{ position: "relative", marginTop: 16 }}>
                <MapWrap
                  objects={crime.map((e) => ({ ...e, x: e.y, y: e.x }))}
                  handleSelect={selectCrime}
                  handleMultipleSelect={selectCrime}
                  height="490px"
                  center={[54, 46.95]}
                  zoom={12}
                />
              </div>
            </Col>
            <Card span={isMobile ? 24 : 8}>
              <DtpTable
                title={t("publicSafety.crime.crimeCard")}
                object={selected}
                tableRows={tableRows}
              />
            </Card>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col span={24}>
              <LoadingSpinner loading={loading}>
                <Title>{t("publicSafety.crime.crimeCategory")}</Title>
                <HorizontalBarChart
                  data={crimeByCategoryValues}
                  noCellSize
                  id="categoryChart"
                  color="#EB5757"
                  height={chartHeight}
                  handleClick={(category) => {
                    this.showModal("category", category);
                  }}
                  advancedScroll
                  advancedScrollStart={0.5}
                  advancedScrollEnd={1}
                  key={crime.length}
                  responsive
                />
                {/* График с экспортом */}
                {/* <HorBar
                  data={crimeByCategoryValues}
                  noCellSize
                  id="categoryChart"
                  color="#EB5757"
                  height={chartHeight}
                  handleClick={(category) => {
                    this.showModal("category", category);
                  }}
                  values={[{ title: "Кол-во", key: "value" }]}
                  scrollStart={0.2}
                  showValues
                  key={crime.length}
                  responsive
                /> */}
              </LoadingSpinner>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="half-screen-height">
            <Col span={isMobile ? 24 : 12}>
              <Title>{t("publicSafety.crime.regions")}</Title>
              <LoadingSpinner loading={loading}>
                <HorizontalBarChart
                  data={crimeByRegionValues}
                  noCellSize
                  id="regionChart"
                  key={crime.length}
                  handleClick={(category) => {
                    this.showIncision(category);
                  }}
                  advancedScroll
                  advancedScrollStart={0.5}
                  advancedScrollEnd={1}
                  color="#34C792"
                  height={chartHeight}
                  responsive
                />
              </LoadingSpinner>
            </Col>

            <Col span={isMobile ? 24 : 12}>
              <Title>{t("publicSafety.crime.registrationAuthority")}</Title>
              <LoadingSpinner loading={loading}>
                <HorizontalBarChart
                  noCellSize
                  data={crimeByOrganValues}
                  id="organChart"
                  height={chartHeight}
                  key={crime.length}
                  handleClick={(category) => {
                    this.showModal("organ", category);
                  }}
                  advancedScroll
                  advancedScrollStart={0.5}
                  advancedScrollEnd={1}
                  color="#458FD8"
                  responsive
                />
              </LoadingSpinner>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col span={isMobile ? 24 : 8}>
            <div className="text-center">
              <FilterSelect
                options={crimeByCategoryLabels}
                filterField="crName"
                handleChange={(filter) =>
                  setFeaturedCategory({
                    category: filter.crName,
                    index: 0,
                  })
                }
                placeholder={t("publicSafety.crime.category")}
                value={[featuredCategories[0]]}
              />
            </div>
            <LoadingSpinner loading={loading}>
              <LineChartWrap
                id="fraudCrimes"
                data={firstGraphicsValues}
                key={firstGraphicsValues.length}
                height={chartHeight}
                // responsive
              />
            </LoadingSpinner>
          </Col>
          <Col span={isMobile ? 24 : 8}>
            <div className="text-center">
              <FilterSelect
                options={crimeByCategoryLabels}
                filterField="crName"
                handleChange={(filter) =>
                  setFeaturedCategory({
                    category: filter.crName,
                    index: 1,
                  })
                }
                placeholder="Категория"
                value={[featuredCategories[1]]}
              />
            </div>
            <LoadingSpinner loading={loading}>
              <LineChartWrap
                id="vandalCrimes"
                data={secondGraphicsValues}
                key={secondGraphicsValues.length}
                height={chartHeight}
              />
            </LoadingSpinner>
          </Col>
          <Col span={isMobile ? 24 : 8}>
            <div className="text-center">
              <FilterSelect
                options={crimeByCategoryLabels}
                filterField="crName"
                handleChange={(filter) =>
                  setFeaturedCategory({
                    category: filter.crName,
                    index: 2,
                  })
                }
                placeholder="Категория"
                value={[featuredCategories[2]]}
              />
            </div>
            <LoadingSpinner loading={loading}>
              <LineChartWrap
                id="thiefCrimes"
                height={chartHeight}
                data={thirdGraphicsValues}
                key={thirdGraphicsValues.length}
              />
            </LoadingSpinner>
          </Col>
        </Row>
        <Modal
          title="Обращения"
          className="appeals_modal"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width={1200}
          bodyStyle={{
            height: 400,
          }}
        >
          <div className="table_height">
            {records.length > 0 && (
              <Table
                headers={[
                  { label: t("publicSafety.crime.category"), key: "crName" },
                  { label: t("publicSafety.crime.severity"), key: "hardCode" },
                  { label: t("publicSafety.crime.article"), key: "article" },
                  {
                    label: t("publicSafety.crime.dateOfUD"),
                    key: "date_sover",
                  },
                  {
                    label: t("publicSafety.crime.dateOfInitiationOfUD"),
                    key: "date_vozb",
                  },
                  { label: t("publicSafety.crime.KUI"), key: "udv" },
                  { label: t("publicSafety.crime.authority"), key: "organ" },
                  { label: t("publicSafety.crime.street"), key: "street" },
                  { label: t("publicSafety.crime.house"), key: "flat" },
                  { label: "Разрезность", key: "incision" },
                ]}
                height="75vh"
                objects={records}
              />
            )}
          </div>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Crime);
