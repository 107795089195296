import React, { Component } from "react";
import moment from "moment";

import CustomModal from "./CustomModal";
import FromStart from "./FromStart";
import InRange from "./InRange";

import ExportExcel from "../../../components/ExportExcelNew";
import { DatePickerR } from "../../../components/DatePickerR";
import { FilterItem } from "../../../components/FilterItemNew/FilterItem";
import { FilterWrapper } from "../../../components/FilterWrapper/FilterWrapper";
import { MainWrapper } from "../../../components/MainWrapper/MainWrapper";
import { RowWrapper } from "../../../components/RowWrapper/RowWrapper";
import { SelectList } from "../../../components/SelectList/SelectList";
import cubejs from "../../../cube";

const cubejsApi = cubejs({ appId: 1 });
export default class AppealsCitizen extends Component {
  state = {
    range: [
      moment().startOf("month").utc(6).startOf("day"),
      moment().utc(6).endOf("day"),
    ],
    showModal: false,
    name: null,
    type: null,
    exportData: [],
    regions: [],
    disabledRegion: false,
    disabledDistrict: false,
    selectedRegions: [],
    districts: [],
    selectedDistricts: [],
    isFromStart: false,
  };

  toggleShowModal = (e, name, isFromStart) => {
    let type = typeof e === "string" ? e : null;
    this.setState({
      showModal: !this.state.showModal,
      type,
      name,
      isFromStart,
    });
  };

  async componentDidMount() {
    let role = JSON.parse(localStorage.getItem("user_params"));
    this.loadDictionaries();
    if (role?.roles?.[0]?.region?.length) {
      this.checkForRegion();
    } else {
      this.getData();
    }
  }

  checkForRegion = () => {
    let role = JSON.parse(localStorage.getItem("user_params"));
    if (role?.roles?.[0]?.region?.length) {
      const selectedRegions = role.roles[0].region
        .filter((region) => !region?.parentId)
        .map((region) => region.regionName);

      /*
       * Эта часть кода была создана специально
       * для учетных записей user-krg-bkh и
       * user-krg-kzb. Данный кейс является
       * уникальным, поэтому логика
       * прописывалась исключительно под них.
       */

      let krgDistrict = [];

      if (role.userId === 44) {
        krgDistrict = [role.roles[0].region[0].child[0].regionName];
      } else if (role.userId === 45) {
        krgDistrict = [role.roles[0].region[0].child[1].regionName];
      }

      this.setState(
        {
          selectedRegions,
          selectedDistricts: krgDistrict,
          disabledRegion: true,
          disabledDistrict: krgDistrict.length || false,
        },
        () => this.getData()
      );
    }
  };

  loadDictionaries = async () => {
    const regions = await cubejsApi.load({
      dimensions: [
        "AppealsRegion.nameRu",
        "AppealsRegion.parentId",
        "AppealsRegion.id",
      ],
      filters: [
        {
          member: "AppealsRegion.parentId",
          operator: "notSet",
        },
      ],
    });

    const districts = await cubejsApi.load({
      dimensions: [
        "AppealsRegion.nameRu",
        "AppealsRegion.parentId",
        "AppealsRegion.id",
      ],
      filters: [
        {
          member: "AppealsRegion.parentId",
          operator: "set",
        },
      ],
    });
    this.setState({
      regions: regions.rawData(),
      districts: districts.rawData(),
    });
  };

  applyFilters = (filter, values) => {
    const { regions, districts, selectedRegions } = this.state;
    if (filter === "selectedRegions" && !values.length) {
      this.setState({ selectedDistricts: [] });
    }
    if (filter === "selectedDistricts" && selectedRegions.length === 0) {
      const districtObject = districts.find(
        (district) => district["AppealsRegion.nameRu"] === values[0]
      );
      const regionObject = regions.find(
        (region) =>
          region["AppealsRegion.id"] ===
          districtObject["AppealsRegion.parentId"]
      );
      this.setState({
        selectedRegions: [regionObject["AppealsRegion.nameRu"]],
      });
    }
    this.setState({
      [filter]: values,
    });
  };

  handleRange = (val) => {
    this.setState({ range: val }, this.getData);
  };

  getData = () => {
    const { range, selectedDistricts, selectedRegions } = this.state;
    const filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: range,
      },
    ];

    if (selectedRegions?.length) {
      filters.push({
        member: "Appeals.region",
        operator: "equals",
        values: selectedRegions,
      });
    }
    if (selectedDistricts?.length) {
      filters.push({
        member: "Appeals.district",
        operator: "equals",
        values: selectedDistricts,
      });
    }
    let query = [
      {
        measures: ["Appeals.appealsNum"],
        dimensions: ["Appeals.appealType", "Appeals.source"],
        filters: [
          {
            member: "Appeals.appealType",
            operator: "contains",
            values: ["Запрос информации"],
          },
          ...filters,
        ],
        order: {
          "Appeals.source": "asc",
        },
        renewQuery: true,
      },
      {
        measures: ["Appeals.appealsNum"],
        dimensions: ["Appeals.appealType", "Appeals.source"],
        filters: [
          {
            member: "Appeals.appealType",
            operator: "contains",
            values: ["Инцидент"],
          },
          ...filters,
        ],
        order: {
          "Appeals.source": "asc",
        },
        renewQuery: true,
      },
      {
        measures: ["Appeals.appealsNum"],
        dimensions: ["Appeals.appealType", "Appeals.source"],
        filters: [
          {
            member: "Appeals.appealType",
            operator: "contains",
            values: ["Обращение"],
          },
          ...filters,
        ],
        order: {
          "Appeals.source": "asc",
        },
        renewQuery: true,
      },
    ];
    query = query.map((e) => cubejsApi.load(e));
    Promise.all(query).then((r) => {
      this.setState({
        exportData: [...r[0].rawData(), ...r[1].rawData(), ...r[2].rawData()],
      });
    });
  };

  filterDistricts = () => {
    const { districts, regions, selectedRegions } = this.state;
    return districts
      .filter((district) => {
        if (!selectedRegions.length) {
          return true;
        }
        const ids = [];
        selectedRegions.forEach((region) => {
          const regionObj = regions.find(
            (it) => region === it["AppealsRegion.nameRu"]
          );
          ids.push(regionObj?.["AppealsRegion.id"]);
        });
        return ids.includes(district["AppealsRegion.parentId"]);
      })
      .map((it) => it["AppealsRegion.nameRu"]);
  };

  render() {
    const {
      range,
      selectedRegions,
      selectedDistricts,
      regions,
      disabledRegion,
      disabledDistrict,
      isFromStart,
    } = this.state;

    return (
      <MainWrapper>
        <FilterWrapper>
          <FilterItem>
            <SelectList
              label="Регион:"
              mode="multiple"
              value={selectedRegions}
              disabled={disabledRegion}
              onChange={(value) => this.applyFilters("selectedRegions", value)}
              defaultValue={[]}
              size="small"
              allowClear
              list={regions.map((it) => it["AppealsRegion.nameRu"])}
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label="Населенный пункт:"
              mode="multiple"
              value={selectedDistricts}
              onChange={(value) =>
                this.applyFilters("selectedDistricts", value)
              }
              defaultValue={[]}
              disabled={disabledDistrict}
              size="small"
              allowClear
              list={this.filterDistricts()}
            />
          </FilterItem>
          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={this.handleRange}
              showTime={{
                secondStep: 60,
                minuteStep: 15,
              }}
              allowClear={false}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
          <FilterItem action>
            <ExportExcel
              filename={`Отчет по источникам обращений
              (${selectedRegions.length !== 0 ? selectedRegions : ""}, ${
                selectedDistricts.length !== 0 ? selectedDistricts : ""
              }) за ${range[0].format(
                "YYYY-MM-DD HH:mm:ss"
              )} – ${range[1].format("YYYY-MM-DD HH:mm:ss")}`}
              data={this.state.exportData}
              fields={[
                {
                  dataIndex: "Appeals.appealType",
                  title: "Тип",
                },
                {
                  dataIndex: "Appeals.source",
                  title: "Источник",
                },
                {
                  dataIndex: "Appeals.appealsNum",
                  title: "Количество",
                },
              ]}
            />
          </FilterItem>
        </FilterWrapper>
        <RowWrapper>
          <FromStart
            toggleShowModal={this.toggleShowModal}
            regions={selectedRegions}
            districts={selectedDistricts}
          />
          <InRange
            toggleShowModal={this.toggleShowModal}
            regions={selectedRegions}
            districts={selectedDistricts}
            range={range}
          />
        </RowWrapper>

        <CustomModal
          district={selectedDistricts}
          region={selectedRegions}
          type={this.state.type}
          name={this.state.name}
          showModal={this.state.showModal}
          toggleShowModal={this.toggleShowModal}
          isFromStart={isFromStart}
          filters={{
            member: "Appeals.submissionDate",
            operator: "inDateRange",
            values: range,
          }}
        />
      </MainWrapper>
    );
  }
}
